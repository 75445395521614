<template>
  <div class="buy-box">
    <Header :title="'立即买入'" :back="true"/>
    <div class="buy box-items">
      <div class="buy-money">买入金额</div>
      <div class="buy-input">
        <div class="buy-fh">$</div>
        <div class="buy-all" @click="buyAmount=canAmount">全部</div>
        <el-input type="number" v-model.number="buyAmount" placeholder="最低买入金额1000美金"/>
      </div>
      <div class="error-tips">{{error}}</div>
      <div class="flex-box">
        <!-- title="投资限额" -->
        <div>当前可投资金额：</div>
        <div class="theme-color">${{canAmount|format}} 
          <el-popover
            placement="bottom"
            width="250"
            trigger="click"
            >
            <div style="text-align:center;margin:10px" class="theme-color">投资限额</div>
            <div>定期美元理财产品不限购买次数，投资限额为200,000美元。</div>
            <div>即您的本次投资金额上限=$200,000-当前共投资本金（不含利息）。</div>
            <i slot="reference" class="el-icon-question"></i>
          </el-popover>
        </div>
      </div>
      
      <div class="flex-box">
        <div>预计计息时间：</div>
        <div class="theme-color">{{interestDay()}}（星期四）</div>
      </div>
      <div class="flex-box">
        <div>锁定期：</div>
        <div class="theme-color">{{days}}天</div>
      </div>
      <div class="buy-btn">
        <div class="defined-btn" @click="handerConfirm">确认买入</div>
      </div>
    </div>
    <div class="flex-box next-steps box-items">
      <div>到期后自动转入下一期</div>
      <div>
        <el-switch
          v-model="automatic"
          active-color="#002D91"
          >
        </el-switch>
      </div>
    </div>
    <div class="agreement">
      <el-checkbox v-model="agreement"></el-checkbox>
      <div>已知悉投风险，同意<a href="javascript:;" class="theme-color" @click="xy">《产品申购协议》</a></div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      error:'',
      periods:this.$route.query.periods,//期数
      days:this.$route.query.days,//锁定期
      buyAmount:'',// 买入金额
      moniesMin:'',
      moniesMax:'',
      canAmount:0,// 可投资金额
      automatic:false, //自动转入下一期
      agreement:true // 协议
    }
  },
  created(){
    this.getStages()
  },
  
  methods:{
    getStages(){
      this.$store.dispatch('stages/getIncome',{params:{product:'akulaku',term:this.periods}}).then(res => {
        if(res.term){
          this.moniesMin=res.term.doorsill
          this.moniesMax=res.term.max
          this.canAmount=res.term.rest// 可投资金额
        }
      })
    },
    
    handerConfirm(){
      if(!this.buyAmount){
        this.error = "请输入买入金额"
        return false
      }else if(this.dataNumber(this.buyAmount)==false){
        this.error = "暂不支持小数，请重新输入";
        return false
      }else if(Number(this.buyAmount)<Number(this.moniesMin)){
        this.error = "最低买入金额为"+this.moniesMin+"美金"
        return false
      }else if(Number(this.buyAmount)>Number(this.canAmount)){
        this.error ="最高买入金额为"+this.canAmount+"美金"
        return false
      } else if(!this.agreement){
        this.error ="请同意申购协议"
        return false
      }
      this.$router.push({path:'pay',query:{periods:this.periods,mo:this.buyAmount,automatic:this.automatic}})
    },
    xy(){
      this.$router.push({path:'subscription'})
    }
  }
}
</script>
<style lang="scss" scoped>
.buy{
  background: #fff;
  padding:3% 2%;
  margin-top:2.5%;
  .buy-money{
    padding-left:3%;
    font-size: .9rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  .buy-input{
    margin: 10px;
    margin-bottom: 0;
    position: relative;
    .el-input{
      // font-size: 16px;
      ::v-deep{
        .el-input__inner{
          padding:0% 7% ;
        }
      }
    }
    .buy-fh{
      position: absolute;
      line-height: 40px;
      // top:12px;
      left: 10px;
      z-index: 2;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
    .buy-all{
      position: absolute;
      z-index: 2;
      line-height: 40px;
      right: 10px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }
  .flex-box{
    font-size: .9rem;
    line-height: 2rem;
    padding-left: 3% ;
    color: #464646;;
  }
  .buy-btn{
    text-align: center;
    margin: 3% auto;
  }
}
.next-steps{
  // margin: 3% 0%;
  // width: ;
  padding: 3%;
  margin-top:2.5%;
  background: #fff;
  color:#000;
  font-size: .9rem;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  line-height: 1;
}
.buy-box{
  position: relative;
  height: 100vh;
  .agreement{
    margin-top:4%;
    padding-left:4%;
    // position: absolute;
    // width: 100%;
    // bottom: 5%;
    display: flex;
    // justify-content: center;
    font-size: .8rem;
    color: #888;
    .el-checkbox{
      margin-right: 4px;
      .el-checkbox__inner{
        border-radius:50%;
      }
    }
  }
}
.error-tips{
  padding: 0px 3%;
  height: 20px;

}
</style>