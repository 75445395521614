var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buy-box" },
    [
      _c("Header", { attrs: { title: "立即买入", back: true } }),
      _c("div", { staticClass: "buy box-items" }, [
        _c("div", { staticClass: "buy-money" }, [_vm._v("买入金额")]),
        _c(
          "div",
          { staticClass: "buy-input" },
          [
            _c("div", { staticClass: "buy-fh" }, [_vm._v("$")]),
            _c(
              "div",
              {
                staticClass: "buy-all",
                on: {
                  click: function ($event) {
                    _vm.buyAmount = _vm.canAmount
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _c("el-input", {
              attrs: { type: "number", placeholder: "最低买入金额1000美金" },
              model: {
                value: _vm.buyAmount,
                callback: function ($$v) {
                  _vm.buyAmount = _vm._n($$v)
                },
                expression: "buyAmount",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "error-tips" }, [_vm._v(_vm._s(_vm.error))]),
        _c("div", { staticClass: "flex-box" }, [
          _c("div", [_vm._v("当前可投资金额：")]),
          _c(
            "div",
            { staticClass: "theme-color" },
            [
              _vm._v("$" + _vm._s(_vm._f("format")(_vm.canAmount)) + " "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    width: "250",
                    trigger: "click",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "theme-color",
                      staticStyle: { "text-align": "center", margin: "10px" },
                    },
                    [_vm._v("投资限额")]
                  ),
                  _c("div", [
                    _vm._v(
                      "定期美元理财产品不限购买次数，投资限额为200,000美元。"
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      "即您的本次投资金额上限=$200,000-当前共投资本金（不含利息）。"
                    ),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-question",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flex-box" }, [
          _c("div", [_vm._v("预计计息时间：")]),
          _c("div", { staticClass: "theme-color" }, [
            _vm._v(_vm._s(_vm.interestDay()) + "（星期四）"),
          ]),
        ]),
        _c("div", { staticClass: "flex-box" }, [
          _c("div", [_vm._v("锁定期：")]),
          _c("div", { staticClass: "theme-color" }, [
            _vm._v(_vm._s(_vm.days) + "天"),
          ]),
        ]),
        _c("div", { staticClass: "buy-btn" }, [
          _c(
            "div",
            { staticClass: "defined-btn", on: { click: _vm.handerConfirm } },
            [_vm._v("确认买入")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "flex-box next-steps box-items" }, [
        _c("div", [_vm._v("到期后自动转入下一期")]),
        _c(
          "div",
          [
            _c("el-switch", {
              attrs: { "active-color": "#002D91" },
              model: {
                value: _vm.automatic,
                callback: function ($$v) {
                  _vm.automatic = $$v
                },
                expression: "automatic",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "agreement" },
        [
          _c("el-checkbox", {
            model: {
              value: _vm.agreement,
              callback: function ($$v) {
                _vm.agreement = $$v
              },
              expression: "agreement",
            },
          }),
          _c("div", [
            _vm._v("已知悉投风险，同意"),
            _c(
              "a",
              {
                staticClass: "theme-color",
                attrs: { href: "javascript:;" },
                on: { click: _vm.xy },
              },
              [_vm._v("《产品申购协议》")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }